/* styles.css */



body {
  overflow-x: hidden;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
  background-color: #fff;
}

.navbar {
  top: 0; /* Aligns navbar at the very top */
  left: 0; /* Aligns navbar to the left edge */
  width: 100%; /* Ensures navbar spans the full width */
  height: 28em;
  padding: 0.75em 1.125em;
  display: absolute;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Ensures navbar is above other content */
  color: white; /* Ensures text in the navbar is white */
  background-image: url('./visax.jpg'); /* Adjust the path based on your project structure */
  background-size: cover;
  background-position: center;
}

.button-container {
  position: fixed; /* Fix the container at the bottom */
  bottom: 3.125em; /* Distance from the bottom */
  display: flex; /* Use flexbox for alignment */
  gap: 0.625em; /* Space between buttons */
}

.logo {
  position: absolute;
  top: 2.5em;
  height: 4.0625em; /* Sets logo size */
}

#myLinks {
  display: none; /* Initially hides the dropdown links */
  color: black;
  padding: 0.625em; /* Larger padding for bigger click area */
  font-size: 2.5em; /* Larger font size */
  background: #fff; 
  border: 0.125em;
}

.navbar a {
  padding: 0.875em 1em;
  text-decoration: none;
  font-size: 1em;
  display: block;
  color: black;
}


/* Commented out hover styles for .logo-div a */
/*
.logo-div a:hover {
  background-color: transparent; 
  color: black; 
}
*/

.navbar a:hover {
  background-color: transparent; /* Grey background on hover */
  color: black; /* Text color change on hover */
}

.navbar-services {
  height: 9em; /* New height for the services page */
}

.navbar-other {
  height: 9em; /* New height for the services page */
}

.h1 {
  color: #323252;
}

.h2 {
  text-align: center;
  color: #858585;
}

.container {
  text-align: center;
  background: white;
  padding: 1.25em;
  border-radius: 0.5em;
  box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1);
}

h1 {
  margin-bottom: 2.1875em;
}

form {
  margin-bottom: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 25em;
}

pre {
  text-align: left;
  background: #f4f4f4;
  padding: 0.625em;
  border-radius: 0.25em;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-buttons {
  position: absolute;
  top: 3.4375em;
  display: flex;
  gap: 1.25em; /* Adjust the spacing as needed */
  align-items: center;
  right: 4.375em;
}

.nav-buttons a {
  color: white;
  padding: 0.625em 1.25em;
  text-decoration: none;
}

.nav-buttons a:hover {
  background-color: #ddd; /* Adjust the hover effect as needed */
}

.content {
  padding-top: 0em; /* Adjust based on the height of your navbar */
}

/* Custom styles for Home component */
.home-container {
  display: flex;
  flex-direction: row; /* Change this to row for side-by-side layout */
  align-items: center;
  justify-content: space-around; /* Distribute space around items */
  width: 100%; /* Ensure full width */
  box-sizing: border-box;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .home-container {
    margin-top: 50%;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    justify-content: center; /* Center items */
  }

  .navbar {
    flex-direction: row; /* Stack items vertically */
    right: 1.375em;
    height: 12em;
    position: fixed;
  }

  .nav-buttons{
    top: 9.375em;
    left: 1.375em;
  }

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Adjust width to give more space to the banner */
    text-align: center; /* Center text */
  }

  .buttons{
    width: 100%;
  }

  .service-boxes {
    display: flex;
    flex-direction: column !important;
    justify-content: center;

}
  .scroll-image{
    display: none;
  }

  html{
    overflow-x: hidden;
  }
  

  .service-content{
    margin-top: 5em;
  }

  .content-contact{
    margin-top: 12em;
    display: flex;
    flex-direction: column;
  }

  .terms{
    margin-top: 15em;
    padding-left: 1em !important;
  }

  .privacy{
    margin-top: 15em;
    padding-left: 1em !important;
  }
  
  .contact-message{
    font-size: small;
    width: 100% !important;
  }

  .contact-form{
    width: 50% !important;
    padding-left: 0% !important;
    padding-top: 4%;

  }

  table {
    font-size:x-small;
  }

}

/* Media query for medium screens */
@media (min-width: 769px) and (max-width: 1024px) {

}

/* Media query for large screens */
@media (min-width: 1025px) {

}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%; /* Adjust width to give more space to the banner */
  text-align: center; /* Center text */
}

.banner h1 {
  font-size: 3.4em;
  text-align: left;
}

.button {
  padding: 0.9375em 3.125em; /* Increased padding for a larger button */
  background: linear-gradient(to right, #63ba9f, #43d6a8); /* Gradient from darker blue to lighter blue */
  color: white; /* Text color remains white */
  border: none;
  border-radius: 1em; /* Slightly more rounded corners */
  cursor: pointer;
  font-size: 1.125em; /* Increased font size for better readability */
  transition: opacity 0.3s ease-out
}

.button:hover {
  opacity: 80%;
}

.buttons {
  width: 30%; /* Adjust width */
  display: flex;
  justify-content: center;
  flex-direction: row; /* Keep buttons stacked vertically */
  gap: 1.25em; /* Space between buttons */
}

.button2 {
  padding: 0.9375em 3.125em; /* Match the styling of the .button class */
  background-color: white; 
  border-color: black;
  border-width: 0.0625em;
  color: black; /* Text color remains white */
  border-radius: 1em; /* Slightly more rounded corners */
  cursor: pointer;
  font-size: 1.125em; /* Increased font size for better readability */
  transition: background-color 0.3s ease-out
}

.button2:hover {
  background-color: #e7e7e7; 
}

.highlight {
  color: #63ba9f; /* Different color for highlighted words */
}

.home-services {
  padding-top: 0em;
}

@keyframes fading {
  from {opacity: 100%; padding-top: 0em;}
  to {opacity: 0; padding-top: 3.125em;}
}

.arrow {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 1.25em; /* Position it near the bottom of the viewport */
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  transition: opacity 0.5s ease; /* Smooth transition for hiding */
  z-index: 1000; /* Ensure it stays on top */
}

.arrow.hide {
  opacity: 0;
}

.scroll-image {
  width: 4%;
}

.content-contact {
  display: flex;
  padding: 2.2em; /* Adjust based on the height of your navbar */
}

.contact-message {
  width: 45%;
}

.contact-form {
  padding-left: 4em;
}

.service-boxes {
  display: flex;
  justify-content: center;
  gap: 4em;
  flex-direction: row;
  padding-bottom: 2em;
  align-items: center;
}

.service-box {
  width: 30em;
  height: 35em;
  border-width: 0.0625em;
  border-radius: 1em;
  background-color: white;
  transition: background-color 0.5s ease-out;
  box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1);
  color: white;
  justify-content: center;
  padding: 0; /* Ensure no extra padding */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.service-box-1:hover,
.service-box-2:hover,
.service-box-3:hover {
  opacity: 80%;
}

.service-box-1,
.service-box-2,
.service-box-3 {
  border-radius: 1em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding: 1em; /* Optional padding for text */
  box-sizing: border-box; /* Ensure padding is included in width/height */
  opacity: 100%;
}

.service-boxes h3 {
  margin: 0; /* Remove default margin */
  font-size: 1.75em;
  color: #fff; 
  text-shadow: -0.03125em -0.03125em 0 #000, 0.03125em -0.03125em 0 #000, -0.03125em 0.03125em 0 #000, 0.03125em 0.03125em 0 #000;
}

.service-box-1 {
  background-image: url('./1.jpg'); /* Adjust the path based on your project structure */
}

.service-box-2 {
  background-image: url('./2.jpg'); /* Adjust the path based on your project structure */
}

.service-box-3 {
  background-image: url('3.jpg'); /* Adjust the path based on your project structure */
}

.bottom-banner{
  width: 100%;
  padding: 2em;
  background-image: url('./visax.jpg'); /* Adjust the path based on your project structure */
  background-size: cover;
  background-position: center;
  color: white;
}

.bottom-banner a {
  padding: 0.875em 1em;
  text-decoration: none;
  font-size: 1.0625em;
  display: block;
  color: white;
}

.service-header {
  display: flex;
  margin-left: 3%;
  margin-left: 4.5%;
  padding-top: 5.5em;
}

/* Services */


.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
}

.service-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  margin: 1em 0;
  padding: 3em;
  border-radius: 0.5em;
  box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1);
  width: 80%;
}

.service-section:nth-child(even) {
  flex-direction: row;
}

.service-image {
  width: 20%;
  height: auto;
  border-radius: 0.5em;
  margin: 0 1em;
}

.service-content {
  width: 60%;
  padding: 3em;
}

.service-content h2 {
  color: #323252;
  margin-bottom: 0.5em;
}

.service-content p {
  color: #585858;
}

/* Service table stuff */

table {
  width: 100%;
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
}
th, td {
  padding: 15px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}
tr:hover {
  background-color: #f1f1f1;
}
th {
  background-color: #4CAF50;
  color: white;
}

/* Privacy Policy */

.privacy{
  padding-left: 4em;
}

/* Terms of Service */

.terms{
  padding-left: 4em;
}

